.antd-img-crop-modal .ant-modal-body {
  padding-bottom: 16px;
}
.antd-img-crop-modal .antd-img-crop-container {
  position: relative;
  width: 100%;
  height: 40vh;
  margin-bottom: 16px;
}
.antd-img-crop-modal .antd-img-crop-control {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  margin: 0 16px;
}
.antd-img-crop-modal .antd-img-crop-control.zoom,
.antd-img-crop-modal .antd-img-crop-control.rotate {
  flex: 4;
}
.antd-img-crop-modal .antd-img-crop-control.flip {
  flex: 2;
  min-width: 48px;
}
.antd-img-crop-modal .antd-img-crop-control .ant-slider {
  flex: 1;
  margin: 0 8px 0 12px;
  min-width: 120px;
}
